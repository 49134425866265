<template>
  <ion-page>
    <ion-header mode="ios" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start" hide-back-button="true">
          <ion-back-button text="" default-href="/b2b/customers"></ion-back-button>
        </ion-buttons>
        <ion-title>{{
          $t('add') + ' ' + ($route.query.headId ? $t('customerPage.branch') : $t('company'))
        }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" :scroll-events="true" @ionScroll="handleScroll($event)">
      <step-form :key="Date.now()" />
    </ion-content>
  </ion-page>
</template>

<script>
import { Capacitor } from '@capacitor/core';
import { Keyboard } from '@capacitor/keyboard';
import StepForm from './StepForm.vue';
export default {
  name: 'AddCustomer',
  components: {
    StepForm
  },
  setup() {
    const handleScroll = (event) => {
      if (event && Capacitor.isNativePlatform()) {
        Keyboard.hide();
      }
    };
    return {
      handleScroll
    };
  }
};
</script>
<style src="@/assets/css/newdesign.scss" lang="scss" scoped></style>
